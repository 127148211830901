export default {
  table: 'Mesa',

  navigation: {
    clients: 'Autorizaciones',

    menu: 'Carta',
    allergens: 'Alérgenos',
    search: 'Buscador',
    orders: 'Mis pedidos',
    tables: 'Mesas',

    help: 'Ayuda',
    language: 'Language',

    logout: 'Desconectar',
  },

  home: {
    categories: 'Categorías',
    menu: 'Menús del día',
  },

  allergens: {
    title: 'Alérgenos',
    alert: 'En caso de tener alguna alérgia, favor de seleccionarlo.',
  },

  search: {
    title: 'Buscardor',
    placeholder: '¿Qué te apetece tomar?',
    alert: 'Escribe al menos 3 caracteres para realizar la búsqueda.',
    noResults: 'No hay ningún producto para los criterios de búsqueda.',
  },

  about: {
    title: 'Sobre nosotros',
    legalNotice: 'Aviso legal',
    privacyPolicy: 'Política de privacidad',
    cookiePolicy: 'Política de cookies',
  },

  cart: {
    title: 'Mi pedido',
    noItems: 'En este momento no tienes ningún producto en el carrito.',
    totalPrice: 'TOTAL:',

    checkAuthorizationButton: 'Comprobar autorización',
    placeOrderButton: 'Realizar pedido',

    authorizationDetails1:
      'Para poder realizar el pedido es necesario indicar al camarero el código: ',
    authorizationDetails2:
      '* Una vez autorizado podrás realizar los pedidos que desees.',

    textareaLabel: 'Detalles extra: ',
    textareaHint:
      'Puedes indicar por ejemplo el punto de la carne o si no quieres algún ingrediente en la ensalada.',

    dailyMenu: {
      beverage: 'Bebida',
      firstDish: 'Primero',
      secondDish: 'Segundo',
      dessert: 'Postre',
      coffee: 'Café',
    },
  },

  waiter: {
    title: 'Contactar al camarero',
    options: 'Opciones',
    callWaiter: 'Llamar al camarero',
    callWaiterDescription:
      'Si necesitas que te atendemos en persona selecciona esta opción.',

    askForCheck: 'Pedir la cuenta',
    askForCheckDescription:
      'Si deseas que te traigamos la cuenta selecciona esta opción.',

    payments: {
      cash: 'Efectivo',
      card: 'Tarjeta de crédito',
      ticket: 'Ticket restaurante',
    },

    authorizationDetails1:
      'Tienes que solicitar la autorización al camarero indicando el código: ',
    authorizationDetails2:
      '* Esta solicitud solo se realiza una vez por sesión.',

    paymentMethod: 'Forma de pago',

    textareaLabel: 'Detalles extra: ',
    textareaHint: 'Puedes indicar por ejemplo si necesitas factura.',

    notificationReceived:
      'Hemos recibido correctamente el mansaje, en breve te atendemos.',
    askCheckReceived:
      'Hemos recibido tu solicitud, en breve te traemos la cuenta.',
  },

  login: {
    title: 'Formulario autenticación',
    account: 'Usuario',
    password: 'Contraseña',
    login: 'Entrar',
  },

  dailyMenu: {
    title: 'Menú del día',

    beverage: 'Bebida',
    selectBeverage: 'Selecciona una bebida por favor.',

    coffeeTee: 'Café o té',
    selectCoffeeOrDessert: 'Selecciona un café o un postre favor.',
    selectCoffeeAndDessert: 'Selecciona un café y un postre favor.',

    dessert: 'Postre',
    selectDessertOrCoffee: 'Selecciona un postre o un café favor.',
    selectDessertAndCoffee: 'Selecciona un postre y un café favor.',

    firstDish: 'Primer plato',
    selectOneFirstDish: 'Usted solo puede seleccionar un primer plato.',
    selectTwoFirstDishes: 'Usted puede seleccionar uno o dos primeros platos.',

    secondDish: 'Segundo plato',
    selectOneSecondDish: 'Usted solo puede seleccionar un segundo plato.',
    selectTwoSecondDishes: 'Usted puede seleccionar uno o dos segundos.',

    addToCart: 'Añadir al carrito',
    notes: 'Notas',

    prices: {
      title: 'Precio menú del día', // Menu price of the day
      firstSecondDishes: 'Un primero y un segundo', // A first and a second dish
      onlyFirstDishes: 'Solo primer plato', // Only a first dish
      twoFirstDishes: 'Dos primeros', // Two first dishes
      onlySecondDishes: 'Solo un segundo', // Only a second dish
      twoSecondDishes: 'Dos segundos', // Two second dishes
    },
  },

  orders: {
    title: 'Pedidos',
    noResults: 'No has realizado ningún pedido en esta sesión.',
  },

  clients: {
    title: 'Autorizaciones',
    noResults: 'No hay ningún cliente activo en este momento.',

    statuses: {
      any: 'Cualquiera',
      authorized: 'Autorizados',
      notAuthorized: 'No autorizados',
    },
  },

  snackbar: {
    close: 'Cerrar',
  },

  dialogs: {
    errorUpdatedProductsTitle: 'Error, datos actualizados',
    errorUpdatedProductsDetails:
      'Hemos actualizado los siguientes productos por lo que serán eliminados de tu carrito:',

    accept: 'Aceptar',
    cancel: 'Cancelar',
    remove: 'Eliminar',
    removeDailyMenu: '¿De verdad quieres eliminar este menú?',
  },

  validations: {
    required: 'El campo es obligatorio.',
    email: 'Correo electrónico inválido.',
  },

  errors: {
    error429: {
      toolbarTitle: 'EasyServe Pedidos',
      title: 'Error 429',
      description1: 'Has escaneado el código demasiadas veces.',
      description2: 'para volver a escanear.',

      rescan: 'Vuelve a escanear',
      rescanDetails: 'Puedes volver a escanear el código de tu mesa.',
    },

    error440: {
      toolbarTitle: 'EasyServe Pedidos',

      sessionExpired:
        'Tu sesión ha caducado, por favor, vuelve a escanear el código de la mesa.',
      details: 'segundos para ser redireccionado a la página principal.',
    },

    error401: {
      toolbarTitle: 'EasyServe Pedidos',

      title: 'No estas autenticado',
      details:
        'Escanea el código QR colocado en tu mesa para ver el menú del restaurante.',
    },

    error404: {
      toolbarTitle: 'Error 404',

      title: 'No hemos encontrado lo que estas buscando.',
      details:
        'Escanea el código QR colocado en tu mesa para ver el menú del restaurante.',
    },
  },
};
