<template>
  <div>
    <v-navigation-drawer v-model="drawer" class="blue" dark app>
      <v-list>
        <v-list-item two-line>
          <v-list-item-avatar>
            <!-- FIXME: Use a restaurant logo -->
            <img src="../assets/images/logo_easyserve.png" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="font-weight-medium">
              {{ restaurant.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('table') }} {{ tableNumber }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list v-if="isUser">
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-table-chair</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-select
              class="pt-0"
              :value="table"
              @change="changeTable"
              :items="tables"
              item-text="number"
              item-value="uuid"
              menu-props="auto"
              hide-details
              dense
              single-line
            ></v-select>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'clients' }">
          <v-list-item-action>
            <v-icon>mdi-account-question</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('navigation.clients')
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <!-- Clients Navigation Header -->
      <v-list nav>
        <v-list-item-group>
          <v-list-item @click.stop :to="{ name: 'menu' }">
            <v-list-item-action>
              <v-icon>mdi-book-open-page-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t('navigation.menu') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click.stop :to="{ name: 'allergens' }">
            <v-list-item-action>
              <v-icon>mdi-peanut-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                $t('navigation.allergens')
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="allergens.length > 0">
              <v-chip
                color="green"
                dark
                small
                class="elevation-2 font-weight-medium"
                >{{ allergens.length }}</v-chip
              >
            </v-list-item-action>
          </v-list-item>

          <v-list-item @click.stop :to="{ name: 'search' }">
            <v-list-item-action>
              <v-icon>mdi-text-search</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                $t('navigation.search')
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- 
            De momento deshabilitamos la vista de pedidos para clientes.
            <v-list-item @click.stop :to="{ name: 'orders' }" v-if="isClient">
            <v-list-item-action>
              <v-icon>mdi-cart-arrow-right</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                $t('navigation.orders')
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <v-list dense>
          <v-list-item @click.stop="logout" v-if="isUser">
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                $t('navigation.logout')
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-git</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <!-- GIT VERSION -->
              <v-list-item-title>
                {{ version }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="white" flat>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ restaurant.name }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon @click.stop :to="{ name: 'cart' }">
        <v-badge
          :content="totalItems"
          :value="totalItems"
          color="red elevation-2"
          overlap
        >
          <v-icon>mdi-cart</v-icon>
        </v-badge>
      </v-btn>

      <v-menu left bottom offset-y min-width="150" v-if="languages.length > 1">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <!-- <v-icon>icon-language</v-icon> -->

            <v-avatar tile size="30">
              <img :src="selectedLanguage.image_path" alt="avatar" />
            </v-avatar>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            class="active"
            color="primary"
            v-for="l in languages"
            :key="l.code"
            @click="changeLanguage(l)"
            :input-value="l.code == selectedLanguage.code"
          >
            <v-list-item-avatar tile size="32">
              <v-img :src="l.image_path"></v-img>
            </v-list-item-avatar>
            <v-list-item-title>{{ l.language }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { EASYSERVE_VERSION } from '@/constants';
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    drawer: null,
  }),

  computed: {
    ...mapGetters('application', {
      languages: 'languages', // Needed for the mini badge indicator on the navigation
      selectedLanguage: 'language', // Needed for the mini badge indicator on the navigation
    }),

    ...mapGetters('security', {
      isClient: 'isClient', // Needed for the mini badge indicator on the navigation
      isUser: 'isUser', // Needed for the mini badge indicator on the navigation
      tables: 'tables',
      table: 'table_uuid',
      tableNumber: 'table_number',
    }),

    ...mapGetters('restaurant', {
      restaurant: 'restaurant', // Needed for the title
    }),

    ...mapGetters('allergens', {
      allergens: 'selected', // Needed for the mini badge indicator on the navigation
    }),

    ...mapGetters('cart', {
      totalItems: 'totalItems', // Needed for the mini badge indicator on the navigation
    }),

    version() {
      return EASYSERVE_VERSION;
    },
  },

  methods: {
    changeLanguage(language) {
      this.$store.dispatch('application/changeLanguage', language);
      this.$i18n.locale = language.code;
    },

    changeTable(table) {
      this.$store.dispatch('security/changeTable', table);
    },

    logout() {
      this.$store.dispatch('security/logout').then(() => {
        this.$router.push({ name: 'error440' });
      });
    },
  },
};
</script>

<style></style>
