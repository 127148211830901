import Vue from 'vue';
import Dinero from 'dinero.js';

Vue.filter('currency_money', function (price) {
  if (isNaN(price)) return '';
  if (!price) return '';
  return Dinero({
    amount: parseInt(price),
    currency: 'EUR',
  })
    .setLocale('es-ES')
    .toFormat('$0,0.00');
});

/**
 * Translates an object
 */
Vue.filter('translate', function (obj, i18n) {
  if (!obj) return '';

  if (typeof obj === 'string' || obj instanceof String) return obj;

  return obj[i18n.locale] ? obj[i18n.locale] : obj[i18n.fallbackLocale];
});
