import Vue from 'vue';
import VueRouter from 'vue-router';
import goTo from 'vuetify/lib/services/goto';

// Pages
import EasyServeHome from '@/views/EasyServeHome.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/menu',
  },
  {
    path: '/',
    component: EasyServeHome,
    children: [
      {
        path: 'clients',
        name: 'clients',
        component: () =>
          import(
            /* webpackChunkName: 'clients' */ '@/views/EasyServeClients.vue'
          ),
      },

      {
        path: 'menu',
        name: 'menu',
        // route level code-splitting
        // this generates a separate chunk (menu.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: 'menu' */ '@/views/EasyServeMenu.vue'),
      },

      {
        path: 'menu/category/:category',
        name: 'category',
        // TODO: Cambiar por category o products
        component: () =>
          import(
            /* webpackChunkName: 'categories' */ '@/views/EasyServeCategory.vue'
          ),
      },

      {
        path: 'allergens',
        name: 'allergens',
        // route level code-splitting
        // this generates a separate chunk (allergens.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: 'allergens' */ '@/views/EasyServeAllergens.vue'
          ),
      },

      {
        path: 'cart',
        name: 'cart',
        component: () =>
          import(/* webpackChunkName: 'cart' */ '@/views/EasyServeCart.vue'),
      },

      // {
      //   path: 'orders',
      //   name: 'orders',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: 'orders' */ '@/views/EasyServeOrders.vue'
      //     ),
      // },
    ],
  },

  {
    path: '/daily-menu/:daily_menu',
    name: 'daily-menu',
    component: () =>
      import(
        /* webpackChunkName: 'daily-menu' */ '@/views/EasyServeDailyMenu.vue'
      ),
  },

  {
    path: '/waiter',
    name: 'waiter',
    component: () =>
      import(/* webpackChunkName: 'waiter' */ '@/views/EasyServeWaiter.vue'),
  },

  {
    path: '/search',
    name: 'search',
    component: () =>
      import(/* webpackChunkName: 'search' */ '@/views/EasyServeSearch.vue'),
  },

  {
    path: '/qrcode/:table',
    name: 'scanner',
    component: () =>
      import(/* webpackChunkName: 'scanner' */ '@/views/EasyServeScanner.vue'),
  },

  {
    path: '/waiter/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: 'login' */ '@/views/EasyServeLogin.vue'),
  },

  // {
  //   path: '/help',
  //   name: 'help',
  //   component: () => import(/* webpackChunkName: 'help' */ '@/views/EasyServeHelp.vue')
  // }

  {
    path: '/error/440',
    name: 'error440',
    component: () =>
      import(
        /* webpackChunkName: 'errors' */ '@/views/errors/EasyServeError440.vue'
      ),
  },
  {
    path: '/error/429',
    name: 'error429',
    component: () =>
      import(
        /* webpackChunkName: 'errors' */ '@/views/errors/EasyServeError429.vue'
      ),
  },
  {
    path: '/error/401',
    name: 'error401',
    component: () =>
      import(
        /* webpackChunkName: 'errors' */ '@/views/errors/EasyServeError401.vue'
      ),
  },
  {
    path: '/error/404',
    name: 'error404',
    component: () =>
      import(
        /* webpackChunkName: 'errors' */ '@/views/errors/EasyServeError404.vue'
      ),
  },
  {
    path: '*',
    // FIXME: make a 404 error
    redirect: '/error/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,

  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },

  routes,
});

export default router;
