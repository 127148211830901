// initial state
const state = {
  text: '',
};

// getters
const getters = {
  text(state) {
    return state.text;
  },
};

// actions
const actions = {
  set(context, text) {
    context.commit('set', text);
  },
};

// mutations
const mutations = {
  set(state, text) {
    state.text = text;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
