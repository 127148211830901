import { API_BASE } from '@/constants';
import axios from 'axios';

// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {
  call(context, details) {
    // Call the waiter
    return new Promise((resolve, reject) => {
      // Get the authorization
      const request = context.rootGetters['security/request_authorization'];

      // Add the details from the client
      request.details = details;

      axios
        .post(API_BASE + '/waiter/call', request)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
