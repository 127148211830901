// initial state
const state = {
  language: JSON.parse(localStorage.getItem('selected_language') || '{}'),
  defaultLocale: '',
  languages: [],
};

// getters
const getters = {
  // language(state, getters, rootState) {
  //     return state.language;
  // }
  languages(state) {
    return state.languages;
  },
  language(state) {
    return state.language;
  },
  defaultLocale(state) {
    return state.defaultLocale;
  },
};

// actions
const actions = {
  configure(context, restaurant) {
    // Get restaurant default locale
    const locale = restaurant.config.locale;

    // Set the restaurant default locale
    context.commit('setDefaultLocale', locale);

    // Save all restaurant languages
    context.commit('setLanguages', restaurant.languages);
  },

  changeLanguage(context, language) {
    // Store the language into the local storage
    localStorage.setItem('selected_language', JSON.stringify(language));

    context.commit('changeLanguage', language);
  },
};

// mutations
const mutations = {
  changeLanguage(state, language) {
    state.language = language;
  },

  setLanguages(state, languages) {
    // If there is no langauge selected by the current user (first time scanning a code)
    // We must set the default locale as the selected language
    if (!state.language.code) {
      // Get the default locale
      languages.forEach(language => {
        if (language.code == state.defaultLocale) {
          state.language = language;
        }
      });
    }

    state.languages = languages;
  },

  setDefaultLocale(state, defaultLocale) {
    state.defaultLocale = defaultLocale;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
