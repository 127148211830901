// Axios
import axios from 'axios';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Authentication
 */
const token_type = localStorage.getItem('token_type');
const access_token = localStorage.getItem('access_token');
if (access_token) {
  axios.defaults.headers.common['Authorization'] = token_type + access_token;
}

import Vue from 'vue';
import EasyServeMobile from './EasyServeMobile.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';

import './plugins/filters';

// Event bus
window.events = new Vue();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(EasyServeMobile),
}).$mount('#app');
