import { API_BASE } from '@/constants';
import axios from 'axios';

// initial state
const state = {
  category: {},
  products: [],
  loading: false,
  error: false,
};

// getters
const getters = {
  category(state) {
    return state.category;
  },
  products(state) {
    return state.products;
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
};

// actions
const actions = {
  load(context, category) {
    return new Promise((resolve, reject) => {
      context.commit('error', false);
      context.commit('loading', true);

      // Get the authorization
      const request = context.rootGetters['security/request_authorization'];

      axios
        .post(API_BASE + '/categories/' + category, request)
        .then(response => {
          // FIXME: Always check the response.data.client object (authorization, expiration etc)

          // Set the products and the category
          context.commit('set', response.data);
          resolve(response);
        })
        .catch(error => {
          // Check for 440 error and show the rescan page
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },
};

// mutations
const mutations = {
  set(state, data) {
    state.category = data.category;
    state.products = data.products;
  },
  error(state, value) {
    state.error = value;
  },
  loading(state, value) {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
