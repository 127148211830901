import { API_BASE } from '@/constants';
import axios from 'axios';

// initial state
const state = {
  restaurant: {},
  categories: [],
  products: [],
  daily_menus: [],

  version: 0,

  loading: false,
  error: false,
};

// getters
const getters = {
  restaurant(state) {
    return state.restaurant;
  },
  categories(state) {
    return state.categories;
  },
  products(state) {
    return state.products;
  },
  daily_menus(state) {
    return state.daily_menus;
  },
  version(state) {
    return state.version;
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
};

// actions
const actions = {
  load(context) {
    return new Promise((resolve, reject) => {
      context.commit('error', false);
      context.commit('loading', true);

      // Get the authorization
      const request = context.rootGetters['security/request_authorization'];

      axios
        .post(API_BASE + '/menu', request)
        .then(response => {
          // We need to update the client data only if its a client
          if (context.rootGetters['security/isClient']) {
            context.dispatch('security/updateClient', response.data.client, {
              root: true,
            });
          }

          // Set the allergens in the correct module
          context.dispatch('allergens/set', response.data.allergens, {
            root: true,
          });

          // Set up the languages
          context.dispatch('application/configure', response.data.restaurant, {
            root: true,
          });

          context.commit('set', response.data);
          resolve(response);
        })
        .catch(error => {
          // Check for 440 error and show the rescan page
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  check(context) {
    return new Promise((resolve, reject) => {
      context.commit('error', false);
      context.commit('loading', true);

      // Get the authorization
      const request = context.rootGetters['security/request_authorization'];
      axios
        .post(API_BASE + '/check', request)
        .then(response => {
          // We need to update the client data only if its a client
          if (context.rootGetters['security/isClient']) {
            context.dispatch('security/updateClient', response.data.client, {
              root: true,
            });
          }

          resolve(response);
        })
        .catch(error => {
          // Check for 440 error and show the rescan page
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  getWaiterData() {
    return new Promise((resolve, reject) => {
      axios
        .get(API_BASE + '/waiter/restaurant')
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  set(state, data) {
    state.restaurant = data.restaurant;
    state.categories = data.categories;
    state.products = data.products;
    state.version = data.version;
    state.daily_menus = data.daily_menus;
  },
  error(state, value) {
    state.error = value;
  },
  loading(state, value) {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
