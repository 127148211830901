export default {
  table: 'Table',

  navigation: {
    clients: 'Authorizations',

    menu: 'Menu',
    allergens: 'Allergens',
    search: 'Search',
    orders: 'Orders',
    tables: 'Tables',

    help: 'Help',
    language: 'Language',

    logout: 'Logout',
  },

  home: {
    categories: 'Categories',
    menu: 'Daily specials',
  },

  allergens: {
    title: 'Allergens',
    alert: 'If you have any allergies, please select it.',
  },

  search: {
    title: 'Search',
    placeholder: 'What do you feel like taking?',
    alert: 'Please enter at least 3 characters to search...',
    noResults: 'There are no results for your search criteria.',
  },

  about: {
    title: 'About us',
    legalNotice: 'Legal notice',
    privacyPolicy: 'Privacy policy',
    cookiePolicy: 'Cookies policy',
  },

  cart: {
    title: 'My order',
    noItems: 'You currently have no products in the cart.',
    totalPrice: 'TOTAL:',

    checkAuthorizationButton: 'Check authorization',
    placeOrderButton: 'Place order',

    authorizationDetails1:
      'To place the order it is necessary to indicate the following code to the waiter: ',
    authorizationDetails2:
      '* Once authorized you can make the orders you want.',

    textareaLabel: 'Extra details:',
    textareaHint:
      'You can indicate for example the point of the meat or if you do not want any ingredient in the salad.',

    dailyMenu: {
      beverage: 'Beverage',
      firstDish: 'First dish',
      secondDish: 'Second dish',
      dessert: 'Dessert',
      coffee: 'Coffee',
    },
  },

  waiter: {
    title: 'Get in touch',
    options: 'Options',
    callWaiter: 'Call the waiter',
    callWaiterDescription:
      'If you need us to attend you in person, select this option.',

    askForCheck: 'Ask for the check',
    askForCheckDescription:
      'If you want us to bring you the bill, select this option.',

    payments: {
      cash: 'Cash',
      card: 'Card',
      ticket: 'Restaurant ticket',
    },

    authorizationDetails1:
      'You have to request an authorization from the waiter indicating the code:',
    authorizationDetails2: '* This request is needed only once per session.',

    paymentMethod: 'Payment method',

    textareaLabel: 'Extra details:',
    textareaHint: 'You can indicate for example if you need an invoice.',

    notificationReceived:
      'We have received the message correctly, we will attend you shortly.',
    askCheckReceived:
      'We have received your request, we will bring you the bill shortly.',
  },

  login: {
    title: 'Login form',
    account: 'Username',
    password: 'Password',
    login: 'Login',
  },

  dailyMenu: {
    title: 'Daily special',

    beverage: 'Beverage',
    selectBeverage: 'Please, select a beverage.',

    coffeeTee: 'Coffee or Tee',
    selectCoffeeOrDessert: 'Please select a coffee or a dessert.',
    selectCoffeeAndDessert: 'Please select a coffee and a dessert.',

    dessert: 'Dessert',
    selectDessertOrCoffee: 'Please select a dessert or a coffee.',
    selectDessertAndCoffee: 'Please select a dessert and a coffee.',

    firstDish: 'First dish',
    selectOneFirstDish: 'Please select at most one dish from the list.',
    selectTwoFirstDishes: 'Please select at most one or two dishes.',

    secondDish: 'Second dish',
    selectOneSecondDish: 'Please select at most one dish from the list.',
    selectTwoSecondDishes: 'Please select at most one or two dishes.',

    addToCart: 'Add to cart',
    notes: 'Notes',

    prices: {
      title: 'Menu price of the day',
      firstSecondDishes: 'A first and a second dish',
      onlyFirstDishes: 'Only a first dish',
      twoFirstDishes: 'Two first dishes',
      onlySecondDishes: 'Only a second dish',
      twoSecondDishes: 'Two second dishes',
    },
  },

  orders: {
    title: 'Orders',
    noResults: 'There are no orders placed from this session.',
  },

  clients: {
    title: 'Authorizations',
    noResults: 'There are no active clients at this moment.',

    statuses: {
      any: 'Any',
      authorized: 'Authorized',
      notAuthorized: 'Not authorized',
    },
  },

  snackbar: {
    close: 'Close',
  },

  dialogs: {
    errorUpdatedProductsTitle: 'Error, updated data',
    errorUpdatedProductsDetails:
      'We have updated the following products so they will be removed from your cart:',

    accept: 'Accept',
    cancel: 'Cancel',
    remove: 'Delete',
    removeDailyMenu: 'Are you sure you want to delete this menu?',
  },

  validations: {
    required: 'The field is required.',
    email: 'Invalid e-mail address.',
  },

  errors: {
    error429: {
      toolbarTitle: 'EasyServe Orders',
      title: 'Error 429',
      description1: 'You have scanned the code too many times.',
      description2: 'to scan again.',

      rescan: 'Scan again',
      rescanDetails: 'You can scan your table QR code again.',
    },

    error440: {
      toolbarTitle: 'EasyServe Orders',

      sessionExpired:
        'Your session has expired, please scan the QR code again.',
      details: ' seconds to be redirected to the main page.',
    },

    error401: {
      toolbarTitle: 'EasyServe Orders',

      title: 'You are not authenticated.',
      details:
        "Please scan the QR code placed on your table to view the restaurant's menu.",
    },

    error404: {
      toolbarTitle: 'Error 404',

      title: "We didn't find what are you looking for.",
      details:
        "Please scan the QR code placed on your table to view the restaurant's menu.",
    },
  },
};
