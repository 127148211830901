import { API_BASE, API_ADMIN_BASE } from '@/constants';
import axios from 'axios';

const state = {
  access_token: localStorage.getItem('access_token') || null,
  token_type: localStorage.getItem('token_type') || null,
  expires_at: localStorage.getItem('expires_at') || null,
  autologout: false,

  loading: false,
  error: false,

  /* We need the generated password */
  client: {
    key: localStorage.getItem('client.key') || null,
    password: localStorage.getItem('client.password') || null,
    expires_at: localStorage.getItem('client.expires_at') || null,
    authorized_at: localStorage.getItem('client.authorized_at') || null,
  },

  restaurant_uuid: localStorage.getItem('restaurant_uuid') || null,
  table_uuid: localStorage.getItem('table_uuid') || null,
  tables: JSON.parse(localStorage.getItem('tables') || null),
  table_number: null,

  // We need a scan error variable to
  scanError: null,
};

const getters = {
  isUser(state) {
    if (state.expires_at === null || state.expires_at < new Date()) {
      return false;
    }

    if (state.access_token === null) {
      return false;
    }

    return true;
  },

  isClient(state) {
    if (!state.client.expires_at || state.client.expires_at < new Date()) {
      return false;
    }

    if (!state.client.password) {
      return false;
    }

    return true;
  },

  isAuthorized(state, getters) {
    // A waiter or an authorized user
    return getters.isUser || !!state.client.authorized_at;
  },

  key(state) {
    return state.client.key;
  },

  password(state) {
    return state.client.password;
  },

  request_authorization(state, getters) {
    //
    let authorization = {
      restaurant_uuid: state.restaurant_uuid,
    };

    if (getters.isClient) {
      authorization.client_authorization = state.client.password;
    } else {
      authorization.table_uuid = state.table_uuid;
    }

    return authorization;
  },

  tables(state) {
    return state.tables;
  },

  table_uuid(state) {
    return state.table_uuid;
  },

  table_number(state, getters) {
    if (getters.isClient) {
      return state.table_number;
    }

    if (state.tables) {
      let table = state.tables.find(t => t.uuid == state.table_uuid);
      if (table) return table.number;
    }

    return 0;
  },

  loading(state) {
    return state.loading;
  },

  error(state) {
    return state.error;
  },

  scanError(state) {
    return state.scanError;
  },

  isAuthenticated(state) {
    if (state.expires_at === null || state.expires_at < new Date()) {
      return false;
    }

    if (state.access_token === null) {
      return false;
    }

    return true;
  },

  autologout(state) {
    return state.autologout;
  },
};

const actions = {
  login: (context, credentials) => {
    return new Promise((resolve, reject) => {
      // The Promise used for router redirect in login
      context.commit('loading', true);
      context.commit('error', false);

      axios
        .post(API_ADMIN_BASE + '/login', credentials)
        .then(response => {
          // Remove all client data if any
          context.commit('clearClientData');
          context.commit('login', response.data);
          // you have your token, now log in your user :)
          // dispatch(USER_REQUEST)
          resolve(response);
        })
        .catch(error => {
          context.commit('error', true);
          context.commit('logout');
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  logout: context => {
    return new Promise((resolve, reject) => {
      context.commit('logout');
      axios.post(API_ADMIN_BASE + '/logout').catch(error => reject(error));
      resolve();
    });
  },

  autologout: context => {
    // Clear everything
    context.commit('logout');
    context.commit('autologout');
  },

  scan: (context, payload) => {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      context.commit('error', false);

      // Use the payload to '/qrcode/{table}'
      axios
        .post(
          API_BASE + '/qrcode/' + payload.table,
          context.getters['request_authorization']
        )
        .then(response => {
          // If is a client (we have a passord)
          if (response.data.password) {
            context.commit('logout');
            context.commit('setClient', response.data);
          } else {
            // Looks like a valid user, we need a list of tables now.
            context.commit('clearClientData');
            context.commit('setUser', response.data);
          }

          resolve(response);
        })
        .catch(error => {
          context.commit('error', error);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  updateClient: (context, client) => {
    context.commit('updateClient', client);
  },

  changeTable: (context, table) => {
    if (context.rootGetters['security/isUser']) {
      context.commit('changeTable', table);
    }
  },
};

const mutations = {
  loading(state, value) {
    state.loading = value;
  },
  error(state, error) {
    state.error = true;
    state.scanError = error;
  },
  login(state, data) {
    state.error = false;

    localStorage.setItem('access_token', data.access_token); // store the token in localstorage
    localStorage.setItem('token_type', data.token_type); // store the token type in localstorage
    localStorage.setItem('expires_at', data.expires_at); // store the token expiration date / time in localstorage

    state.access_token = data.access_token;
    state.token_type = data.token_type;
    state.expires_at = data.expires_at;
  },
  logout(state) {
    state.error = false;

    localStorage.removeItem('access_token');
    localStorage.removeItem('token_type');
    localStorage.removeItem('expires_at');

    state.access_token = null;
    state.token_type = null;
    state.expires_at = null;
  },
  autologout(state) {
    state.autologout = true;
  },

  clearClientData(state) {
    state.client = {};
    localStorage.removeItem('client.key');
    localStorage.removeItem('client.password');
    localStorage.removeItem('client.expires_at');
    localStorage.removeItem('client.authorized_at');
    localStorage.removeItem('client.authorized_by');
  },

  setClient(state, data) {
    localStorage.setItem('client.key', data.key);
    localStorage.setItem('client.password', data.password);
    localStorage.setItem('client.expires_at', data.expires_at);

    state.client.expires_at = data.expires_at;
    state.client.password = data.password;
    state.client.key = data.key;

    localStorage.setItem('restaurant_uuid', data.restaurant_uuid);
    state.restaurant_uuid = data.restaurant_uuid;
  },

  updateClient(state, client) {
    // If its a logged in user we dont have a client
    if (!client) {
      return;
    }

    localStorage.setItem('client.key', client.key);
    localStorage.setItem('client.expires_at', client.expires_at);
    localStorage.setItem('client.authorized_at', client.authorized_at);
    localStorage.setItem('client.authorized_by', client.authorized_by);

    state.client.expires_at = client.expires_at;
    state.client.authorized_at = client.authorized_at;
    state.client.authorized_by = client.authorized_by;
    state.client.key = client.key;
    state.table_number = client.table.number;
  },

  setUser(state, data) {
    localStorage.setItem('restaurant_uuid', data.restaurant_uuid);
    localStorage.setItem('table_uuid', data.table_uuid);

    state.restaurant_uuid = data.restaurant_uuid;
    state.table_uuid = data.table_uuid;

    localStorage.setItem('tables', JSON.stringify(data.tables));
    state.tables = data.tables;
  },

  changeTable(state, table) {
    localStorage.setItem('table_uuid', table);
    state.table_uuid = table;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
