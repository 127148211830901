// initial state
const state = {
  dailyMenu: {},
  config: {},

  selectedBeverage: 0,
  selectedFirstDishes: [], // We can have at most 2 of those
  selectedSecondDishes: [], // We can have at most 2 of those
  selectedDessert: 0,
  selectedCoffee: 0,
};

// getters
const getters = {
  selectedBeverage(state) {
    return state.selectedBeverage;
  },
  validBeverage(state) {
    return state.selectedBeverage != 0;
  },

  selectedFirstDishes(state) {
    return state.selectedFirstDishes;
  },
  selectedSecondDishes(state) {
    return state.selectedSecondDishes;
  },

  validDishes(state) {
    // If we have one second and one first dish
    if (
      state.selectedSecondDishes.length == 1 &&
      state.selectedFirstDishes.length == 1
    ) {
      return true;
    }

    // If only one first dish is configured and we only have that selected
    if (
      state.config.only_first_dishes == true &&
      state.selectedFirstDishes.length == 1 &&
      state.selectedSecondDishes.length == 0
    ) {
      return true;
    }

    // If we have 2 first dishes configured and only 2 second dishes selected
    if (
      state.config.two_first_dishes == true &&
      state.selectedFirstDishes.length == 2 &&
      state.selectedSecondDishes.length == 0
    ) {
      return true;
    }

    // If only one second dish is configured and we only have that selected
    if (
      state.config.only_second_dishes == true &&
      state.selectedSecondDishes.length == 1 &&
      state.selectedFirstDishes.length == 0
    ) {
      return true;
    }

    // If we have 2 second dishes configured and only 2 second dishes selected
    if (
      state.config.two_second_dishes == true &&
      state.selectedSecondDishes.length == 2 &&
      state.selectedFirstDishes.length == 0
    ) {
      return true;
    }

    return false;
  },

  selectedDessert(state) {
    return state.selectedDessert;
  },
  validDessert(state) {
    if (state.config.coffee_dessert == true) {
      return state.selectedDessert != 0;
    }

    if (state.selectedCoffee == 0 && state.selectedDessert != 0) {
      return true;
    }

    if (state.selectedCoffee != 0 && state.selectedDessert == 0) {
      return true;
    }

    return false;
  },

  selectedCoffee(state) {
    return state.selectedCoffee;
  },
  validCoffee(state) {
    if (state.config.coffee_dessert == true) {
      return state.selectedCoffee != 0;
    }

    if (state.selectedCoffee == 0 && state.selectedDessert != 0) {
      return true;
    }

    if (state.selectedCoffee != 0 && state.selectedDessert == 0) {
      return true;
    }

    return false;
  },
};

// actions
const actions = {
  setDailyMenu(context, dailyMenu) {
    context.commit('setDailyMenu', dailyMenu);
  },
  clear(context) {
    context.commit('clear');
  },

  selectBeverage(context, beverage) {
    context.commit('selectBeverage', beverage);
  },
  selectFirstDishes(context, firstDishes) {
    context.commit('selectFirstDishes', firstDishes);
  },
  selectSecondDishes(context, secondDishes) {
    context.commit('selectSecondDishes', secondDishes);
  },
  selectDessert(context, dessert) {
    context.commit('selectDessert', dessert);
  },
  selectCoffee(context, coffee) {
    context.commit('selectCoffee', coffee);
  },
};

// mutations
const mutations = {
  setDailyMenu(state, dailyMenu) {
    state.dailyMenu = dailyMenu;
    state.config = dailyMenu.config;
  },

  selectBeverage(state, beverage) {
    state.selectedBeverage = beverage;
  },
  selectFirstDishes(state, firstDishes) {
    state.selectedFirstDishes = firstDishes;
  },
  selectSecondDishes(state, secondDishes) {
    state.selectedSecondDishes = secondDishes;
  },
  selectDessert(state, dessert) {
    state.selectedDessert = dessert;
  },
  selectCoffee(state, coffee) {
    state.selectedCoffee = coffee;
  },

  clear(state) {
    state.selectedBeverage = 0;
    state.selectedFirstDishes = [];
    state.selectedSecondDishes = [];
    state.selectedDessert = 0;
    state.selectedCoffee = 0;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
