<template>
  <v-snackbar v-model="snackbar">
    {{ text | translate($i18n) }}
    <v-btn text @click="snackbar = false">{{ $t('snackbar.close') }}</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data: () => ({
    snackbar: false,
    text: '',
  }),

  created() {
    window.events.$on('snackbar', message => {
      this.text = message;
      this.snackbar = true;
    });
  },
};
</script>

<style></style>
