<template>
  <v-app>
    <router-view></router-view>

    <!-- Global snackbar for every component to use -->
    <easy-serve-snackbar></easy-serve-snackbar>

    <!-- Loading screen -->
    <easy-serve-loading :loading="loading"></easy-serve-loading>
  </v-app>
</template>

<script>
import EasyServeSnackbar from './components/EasyServeSnackbar';
import EasyServeLoading from './components/EasyServeLoading';

import { mapGetters } from 'vuex';

export default {
  components: {
    EasyServeSnackbar,
    EasyServeLoading,
  },

  data: () => ({
    responseError: null,
  }),

  computed: {
    ...mapGetters('restaurant', {
      version: 'version',
      loading: 'loading',
      error: 'error',
    }),
    ...mapGetters('application', {
      defaultLocale: 'defaultLocale',
      language: 'language',
    }),
  },

  watch: {
    $route(to, from) {
      // We MUST check the restaurant version here
      // FIXME: Check for 404 (table not found, client not found etc...)

      // console.log(to);
      // console.log(from);

      // First time will be the scanner only
      if (
        to.name == 'login' ||
        to.name == 'clients' ||
        to.name == 'scanner' ||
        to.name == 'error440' ||
        to.name == 'error429' ||
        to.name == 'error401' ||
        to.name == 'error404'
      ) {
        // Wait for the scanner to do its thing
        return;
      }

      // If we are comming from the scanner we dont have anything loaded
      if (from.name === 'scanner') {
        return this.load();
      }

      // Now we check version every time
      this.checkVersion();
    },
  },

  methods: {
    load() {
      // Load the entire restaurant all over again
      this.$store
        .dispatch('restaurant/load')
        .then(() => {
          // Set the language
          this.$i18n.fallbackLocale = this.defaultLocale;
          this.$i18n.locale = this.language.code;
        })
        .catch(() => {
          // Some loading errors
          // console.error(error.response);
        });
    },

    checkVersion() {
      // Check the current version
      this.$store
        .dispatch('restaurant/check')
        .then(response => {
          // Si esta caducado el cliente hay que salir de la pagina (mostrar error 440)
          if (this.version !== response.data.version) {
            // FIXME: Tenemos que preguntar al usuario si quiere cargar los nuevos datos ya
            // que perdera lo que tiene en el carrito de compras
            this.load();
          }
        })
        .catch(error => {
          this.responseError = error;

          if (error.response.status == 440) {
            // Show an error
            return this.$router.push({ name: 'error440' });
          }

          // If the user does NOT have any authorization code...
          // Is the same error if the order is not valid but this is
          //  checked ONLY on a route change so its fine I think
          if (error.response.status == 422) {
            // Show an error
            return this.$router.push({ name: 'error440' });
          }

          // If the user is not authorized any more (removed from the database)
          if (error.response.status == 401) {
            // Show an error
            return this.$router.push({ name: 'error401' });
          }

          // If the user is trying to guess a table id
          if (error.response.status == 404 || error.response.status == 403) {
            // Show an error
            return this.$router.push({ name: 'error404' });
          }
        });
    },
  },
};
</script>

<style>
@import url('./assets/css/style.css');
@import url('./assets/css/icons.css');
</style>
