<template>
  <v-overlay :value="loading" :opacity="0.75">
    <v-progress-circular indeterminate size="50"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  props: {
    loading: Boolean,
  },
};
</script>

<style></style>
