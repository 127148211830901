import { API_BASE } from '@/constants';
import uniqid from 'uniqid';
import axios from 'axios';

// initial state
const state = {
  /**
   * This items contains the products and the quantity of each
   */
  items: [],

  daily_menus: [],

  details: '',

  loading: false,
  error: false,
};

// getters
const getters = {
  items(state) {
    return state.items;
  },

  daily_menus(state) {
    return state.daily_menus;
  },

  totalPrice(state) {
    // Calculate the total price
    let total = 0;
    state.items.forEach(i => {
      total += i.quantity * i.product.price;
    });

    state.daily_menus.forEach(dailyMenu => {
      total += dailyMenu.total;
    });

    return total;
  },

  totalItems(state) {
    let total = 0;

    state.items.forEach(i => {
      total += i.quantity;
    });

    state.daily_menus.forEach(() => {
      total++;
    });

    return total;
  },

  order_products(state) {
    let products = [];
    state.items.forEach(item => {
      let buyable = {
        id: item.product.id,
        name: item.product.name,
        price: item.product.price,
        quantity: item.quantity,
      };

      products.push(buyable);
    });

    return products;
  },

  order_daily_menus(state) {
    // Daily Menu Stringify filter
    const filter = [
      'beverage',
      'first_dishes',
      'second_dishes',
      'dessert',
      'coffee',
      'id',
      'name',
      'price',
      'group',
      'en',
      'es',
    ];

    let daily_menus = [];

    state.daily_menus.forEach(item => {
      let buyable = {
        id: item.id,
        name: item.name,
        price: item.price,
        total: item.total,
        quantity: item.quantity,
        json_data: JSON.stringify(item.object_data, filter),
      };

      daily_menus.push(buyable);
    });

    return daily_menus;
  },

  details(state) {
    return state.details;
  },

  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
};

// actions
const actions = {
  placeOrder(context) {
    return new Promise((resolve, reject) => {
      context.commit('error', false);
      context.commit('loading', true);

      // Get the authorization
      let request = context.rootGetters['security/request_authorization'];
      request.details = context.getters.details;
      request.products = context.getters.order_products;
      request.daily_menus = context.getters.order_daily_menus;

      axios
        .post(API_BASE + '/orders/place', request)
        .then(response => {
          // Everithing ok, remove all items from cart
          context.commit('clear');
          resolve(response);
        })
        .catch(error => {
          // Check for 440 error and show the rescan page
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },

  add(context, product) {
    context.commit('add', product);
  },

  remove(context, product) {
    context.commit('remove', product);
  },

  addDailyMenu(context, payload) {
    let daily_menu = {
      // Needed an unique one for the vue list key
      unique_id: uniqid(),
      id: payload.dailyMenu.id,
      quantity: 1,
      name: payload.dailyMenu.name,
      price: payload.price, // This price should be calculated based on the selection
      total: payload.total, // We should add the products price to this

      object_data: {
        beverage: payload.beverage,
        first_dishes: payload.first_dishes,
        second_dishes: payload.second_dishes,
        dessert: payload.dessert,
        coffee: payload.coffee,
      },
    };

    context.commit('addDailyMenu', daily_menu);
  },

  /**
   * Removes a daily menu from the cart
   */
  removeDailyMenu(context, daily_menu) {
    context.commit('removeDailyMenu', daily_menu);
  },

  setDetails(context, details) {
    context.commit('setDetails', details);
  },
};

// mutations
const mutations = {
  add(state, product) {
    let exists = false;
    // We must search first for the product
    state.items.forEach(i => {
      if (i.product.id === product.id) {
        // This is the same product, just increment the quantity
        i.quantity++;
        exists = true;
      }
    });

    if (false === exists) {
      const item = {
        quantity: 1,
        product: product,
      };

      state.items.push(item);
    }
  },

  remove(state, product) {
    // Search for the product
    state.items.forEach((item, i) => {
      if (item.product === product) {
        if (--item.quantity == 0) {
          // Remove it from the array
          state.items.splice(i, 1);
        }
      }
    });
  },

  addDailyMenu(state, dailyMenu) {
    state.daily_menus.push(dailyMenu);
  },

  removeDailyMenu(state, dailyMenu) {
    // Search for the item
    state.daily_menus.forEach((elem, i) => {
      if (elem.unique_id == dailyMenu.unique_id) {
        state.daily_menus.splice(i, 1);
      }
    });
  },

  setDetails(state, details) {
    state.details = details;
  },

  clear(state) {
    state.items = [];
    state.daily_menus = [];
    state.details = '';
  },

  error(state, value) {
    state.error = value;
  },
  loading(state, value) {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
