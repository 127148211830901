import { API_BASE } from '@/constants';
import axios from 'axios';

export const BEVERAGES = 'BEVERAGES';
export const FIRST_DISHES = 'FIRST_DISHES';
export const SECOND_DISHES = 'SECOND_DISHES';
export const DESSERTS = 'DESSERTS';
export const COFFEES = 'COFFEES';

// initial state
const state = {
  daily_menu: null,
  config: null,
  products: [],
  loading: false,
  error: false,
};

// getters
const getters = {
  daily_menu(state) {
    return state.daily_menu;
  },
  config(state) {
    return state.config;
  },
  products(state) {
    return state.products;
  },

  beverages(state) {
    return state.products.filter(p => p.group === BEVERAGES);
  },
  first_dishes(state) {
    return state.products.filter(p => p.group === FIRST_DISHES);
  },
  second_dishes(state) {
    return state.products.filter(p => p.group === SECOND_DISHES);
  },
  desserts(state) {
    return state.products.filter(p => p.group === DESSERTS);
  },
  coffees(state) {
    return state.products.filter(p => p.group === COFFEES);
  },

  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
};

// actions
const actions = {
  load(context, daily_menu) {
    return new Promise((resolve, reject) => {
      context.commit('error', false);
      context.commit('loading', true);

      const request = context.rootGetters['security/request_authorization'];
      axios
        .post(API_BASE + '/daily-menus/' + daily_menu, request)
        .then(response => {
          // We need to update the client data only if its a client
          if (context.rootGetters['security/isClient']) {
            context.dispatch('security/updateClient', response.data.client, {
              root: true,
            });
          }

          context.commit('set', response.data);
          resolve(response);
        })
        .catch(error => {
          // Check for 440 error and show the rescan page
          context.commit('error', true);
          reject(error);
        })
        .finally(() => {
          context.commit('loading', false);
        });
    });
  },
};

// mutations
const mutations = {
  set(state, data) {
    state.daily_menu = data.daily_menu;
    state.config = data.daily_menu.config;
    state.products = data.products;
  },
  error(state, value) {
    state.error = value;
  },
  loading(state, value) {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
