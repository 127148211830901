// initial state
const state = {
  allergens: [],
  selected: JSON.parse(localStorage.getItem('selected_allergens') || '[]'),
};

// getters
const getters = {
  allergens(state) {
    return state.allergens;
  },

  selected(state) {
    return state.selected;
  },
};

// actions
const actions = {
  select(context, selected) {
    // Store them into the local storage
    localStorage.setItem('selected_allergens', JSON.stringify(selected));
    context.commit('select', selected);
  },

  set(context, allergens) {
    context.commit('set', allergens);
  },
};

// mutations
const mutations = {
  select(state, selected) {
    state.selected = selected;
  },
  set(state, allergens) {
    state.allergens = allergens;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
