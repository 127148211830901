import { API_BASE } from '@/constants';
import axios from 'axios';

// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {
  ask(context, payload) {
    // Ask for the bill
    return new Promise((resolve, reject) => {
      // Get the authorization
      const request = context.rootGetters['security/request_authorization'];

      // Add the details from the client and the payment method
      request.details = payload.details;
      request.json_data = JSON.stringify({
        payment_type: payload.payment_type,
      });

      axios
        .post(API_BASE + '/bill/ask', request)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
