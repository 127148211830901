import Vue from 'vue';
import Vuex from 'vuex';

import security from './modules/security';
import application from './modules/application';
import allergens from './modules/allergens';
import restaurant from './modules/restaurant';
import categories from './modules/categories';
import dailymenus from './modules/dailymenus';
import dailymenuSelection from './modules/dailymenuSelection';
import search from './modules/search';
import cart from './modules/cart';
import waiter from './modules/waiter';
import bill from './modules/bill';
import orders from './modules/orders';
import clients from './modules/clients';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';
export default new Vuex.Store({
  modules: {
    security,
    application,
    allergens,
    restaurant,
    categories,
    dailymenus,
    dailymenuSelection,
    search,
    cart,
    waiter,
    bill,
    orders,
    clients,
  },
  strict: debug,
});
