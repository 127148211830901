<template>
  <div>
    <easy-serve-navigation></easy-serve-navigation>

    <router-view></router-view>

    <v-fab-transition>
      <v-btn
        color="white"
        fab
        large
        bottom
        right
        fixed
        v-show="!loading"
        :to="{ name: 'waiter' }"
      >
        <v-icon>icon-hand-paper-o</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import EasyServeNavigation from '../components/EasyServeNavigation';
import { mapGetters } from 'vuex';

export default {
  components: {
    EasyServeNavigation,
  },

  data: () => ({
    dialog: false,
  }),

  computed: {
    ...mapGetters('restaurant', {
      loading: 'loading',
    }),
  },
};
</script>

<style></style>
